<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="DDT Produzione"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-layout v-if="!showRicerca">
            <v-flex>
              <v-text-field
                light
                v-model="search"
                prepend-icon="search"
                @click:prepend="getDdts"
                append-icon="cancel"
                @click:append="resetItems"
                placeholder="Cerca" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-btn class="ml-0" flat @click="toggleRicerca()">
                Ricerca avanzata
                <v-icon v-if="!showRicerca">arrow_right</v-icon>
                <v-icon v-if="showRicerca">arrow_drop_down</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="showRicerca">
            <v-flex>
              <v-layout>
                <v-flex xs3>
                  <v-select
                    class="ma-2 ml-4"
                    label="Cliente"
                    :items="clienti"
                    item-text="ragione_sociale"
                    item-value="cliente_id"
                    v-model="ricerca.cliente_id" />
                </v-flex>
                <v-flex xs3>
                  <v-autocomplete
                    class="ma-2"
                    label="Destinazione"
                    :items="destinazioni"
                    item-text="presso"
                    item-value="_id"
                    v-model="ricerca.destinazione_id"
                    :loading="destLoading"
                    :search-input.sync="searchDest"
                    hide-no-data
                    hide-selected
                    clearable
                    />
                </v-flex>
                <v-flex xs3>
                  <v-autocomplete
                    class="ma-2"
                    label="Opera"
                    :items="opere"
                    item-text="nome"
                    item-value="_id"
                    v-model="ricerca.opera_id"
                    :loading="opereLoading"
                    :search-input.sync="searchOpere"
                    hide-no-data
                    hide-selected
                    clearable
                    />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    class="ma-2"
                    label="Codice finito"
                    v-model="ricerca.codice_finito" />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs3>
                  <date-picker
                    class="ma-2 ml-4"
                    v-model="ricerca.data_da"
                    label="Data da"
                    name="data_da" />
                </v-flex>
                <v-flex xs3>
                  <date-picker
                    class="ma-2"
                    v-model="ricerca.data_a"
                    label="Data a"
                    name="data_a" />
                </v-flex>
                <v-flex xs3 class="pa-2">
                  <v-btn
                    class="ml-0"
                    color="primary"
                    @click.native="cerca()">
                    Filtra
                  </v-btn>
                  <v-btn
                    @click="reset_cerca()">
                    Ripristina
                  </v-btn>
                </v-flex>
                <v-flex xs3>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!ddts.length">
          Nessun DDT trovato
        </v-alert>
        <v-layout row wrap v-if="ddts.length">
          <v-flex xs8 />
          <v-flex xs4 class="text-xs-right">
            <v-chip small color="grey dark-3" text-color="white">
              DDT selezionati: {{selected.length}}
            </v-chip>
            <v-menu offset-y>
              <v-btn slot="activator" :color="'primary'" dark>
                Azioni <v-icon>arrow_drop_down</v-icon>
              </v-btn>
              <v-list>
                <!-- v-list-tile @click="stampatutti()">
                  <v-list-tile-title>Stampa tutti</v-list-tile-title>
                </v-list-tile -->
                <!--v-list-tile @click="emailtutti()">
                  <v-list-tile-title>Invia tutti</v-list-tile-title>
                </v-list-tile-->
                <v-divider></v-divider>
                <v-list-tile @click="stampa('zip')" :disabled="!selected.length">
                  <v-list-tile-title>Scarica</v-list-tile-title>
                </v-list-tile>
                <!-- v-list-tile @click="stampa('pdf')" :disabled="!selected.length">
                  <v-list-tile-title>Stampa</v-list-tile-title>
                </v-list-tile -->
                <v-list-tile @click="email()" :disabled="!selected.length">
                  <v-list-tile-title>Invia e-mail</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-checkbox v-model="selettore" :indeterminate="indeterminate" :label="selectAll" @change="seleziona" />
        <v-list class="mt-2" three-line v-if="ddts.length">
          <template v-for="(ddt, index) in ddts">
            <v-list-tile
              @click="$router.push({ name: 'ddt', params: { id: ddt._id} })"
              avatar
              :key="'tile-'+ddt._id">
              <v-list-tile-avatar v-on:click.stop>
                <v-checkbox v-model="selected" :value="ddt._id" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ddt.progressivo}}-{{ddt.esercizio}} - Numero colli: {{ddt.peso_totale.colli}}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <span class="text-uppercase">{{ddt.opera}}</span>
                  <!--span v-if="ddt.prodotto"> - {{ddt.prodotto}}</span-->
                  <v-tooltip right v-if="ddt.more_data.length">
                    <span slot="activator" class="secondary--text"> +{{ddt.more_data.length}}</span>
                    <span v-for="(item, index) in ddt.more_data" :key="'more-'+index">
                      {{item.descrizione}} - <span class="text-uppercase">{{item.codice_finito}}</span><br />
                    </span>
                  </v-tooltip>
                </v-list-tile-sub-title>
                <v-list-tile-sub-title v-text="getAddr(ddt)" />
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text>{{ddt.data}}</v-list-tile-action-text>
                <v-list-tile-action-text class="secondary--text">{{ddt.codice_finito}}</v-list-tile-action-text>
                <v-list-tile-action-text>{{ddt.ragione_sociale_cliente}}</v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="ddt._id" v-if="index < ddts.length - 1" inset></v-divider>
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-tooltip left>
        <v-btn slot="activator"
          color="pink darken-1"
          dark fab fixed right bottom
          @click="$router.push({ name: 'ddt', params: { id: 'add', causale: 'Reso da c/lavorazione' } })">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo DDT</span>
      </v-tooltip>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import datePicker from '@/components/common/DatePicker'
import fileSaver from 'file-saver'
import _debounce from 'lodash/debounce'
import { ddtMixin } from '@/components/produzione/ddt/ddtMixin'

export default {
  mixins: [ddtMixin],
  data: () => ({
    selected: [],
    selectedOnPage: [],
    selettore: false,
    indeterminate: false,
    selectAll: 'Seleziona tutti',
    ddts: [],
    ddt: {},
    search: null,
    pages: 0,
    page: 1,
    paginate: 20, // Di più è troppo lento
    showRicerca: false,
    ricerca: {},
    clienti: [],
    destLoading: false,
    searchDest: null,
    opereLoading: false,
    searchOpere: null,
    destinazioni: [],
    opere: [],
    collane: []
  }),
  components: {
    PageTitle,
    datePicker
  },
  methods: {
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    },
    // ATTENZIONE : ho lasciato il nome della funzione com'era prima ma ora controlla il cliente e non la destinazione, come da richiesta di Dani il 6/11/2018
    stessaDestinazione () {
      if (this.selected && this.selected.length) {
        let dest = 0 // default scemo
        for (let sel of this.selected) {
          let d = this.ddts.find(function (c) { return c._id === sel })
          if (dest === 0) dest = d.cliente_id
          else if (dest !== d.cliente_id) return false
        }
        return true
      } else return false
    },
    seleziona () {
      for (let ddt of this.ddts) {
        let idx = this.selected.indexOf(ddt._id)
        if (this.selettore) {
          if (idx === -1) this.selected.push(ddt._id)
        } else {
          if (idx > -1) this.selected.splice(idx, 1)
        }
      }
      this.changeSel()
    },
    changeSel () {
      this.selectedOnPage = []
      if (this.selected && this.selected.length) {
        for (let ddt of this.ddts) {
          if (this.selected.indexOf(ddt._id) > -1) this.selectedOnPage.push(ddt._id)
        }
      }
      if (this.selectedOnPage.length) {
        this.selettore = true
        this.selectAll = 'Deseleziona tutti'
        this.indeterminate = this.selectedOnPage.length !== this.ddts.length
      } else {
        this.selettore = false
        this.selectAll = 'Seleziona tutti'
        this.indeterminate = false
      }
    },
    getDdts () {
      let o = {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      let processData = function (ddt, item) {
        let desc = item.opera || item.descrizione
        if (item.prodotto) desc += ' - ' + item.prodotto
        if (item.numero) desc += ' - N. ' + item.numero
        if (ddt.opera === null) {
          ddt.opera = desc
          ddt.prodotto = item.prodotto || item.unita_misura
          ddt.codice_finito = item.codice_finito || item.codice
        } else {
          ddt.more_data.push({ codice_finito: item.codice_finito || item.codice, descrizione: desc })
        }
      }

      this.$plsqlReadAll('ddtp', o)
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.ddts = response.data.items
            this.pages = response.data.pages
            this.changeSel()

            this.ddts.forEach(function (ddt) {
              ddt.opera = null
              ddt.prodotto = null
              ddt.codice_finito = null
              ddt.more_data = []

              if (ddt.ordini && ddt.ordini.length) {
                ddt.ordini.forEach(function (o) {
                  processData(ddt, o)
                })
              }

              if (ddt.merce && ddt.merce.length) {
                ddt.merce.forEach(function (o) {
                  processData(ddt, o)
                })
              }
            })
          }
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore' })
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.ricerca = {}
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getDdts()
    },
    stampa (type) {
      let processDdtForMail = this.processDdtForMail
      this.$plsqlMethod('ddtp', 'get_ddt_for_mail', { ddts: this.selected })
        .then((response) => {
          response.data.forEach(function (ddt) {
            ddt = processDdtForMail(ddt)
          })

          this.$http({
            method: 'post',
            url: `/mail/ddt/${type}`,
            responseType: 'blob',
            data: { ddts: response.data }
          }).then((response) => {
            const blob = new Blob([response.data], { type: `application/${type}` })
            if (type === 'zip') {
              fileSaver.saveAs(blob, 'ddt.zip')
            } else {
              this.$openFileNewTab(blob) // con adblocker picche ocio
            }
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare lo zip dei ddt' })
          })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare lo zip dei ddt' })
        })
    },
    email () {
      if (this.stessaDestinazione()) {
        this.$router.push({ name: 'ddtMail', query: { ddts: this.selected } })
      } else {
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile inviare la mail: sono stati selezionati DDT di Clienti diversi' })
      }
    },
    /* stampatutti () {
      let o = {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      this.$plsqlMethod('ddtp', 'read_ids', o)
        .then((response) => {
          this.selected = response.data
          this.stampa('pdf')
          this.selected = []
        }).catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile ottenere gli id dei DDT' })
        })
    },
    emailtutti () {
      let o = {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      this.$plsqlMethod('ddtp', 'read_ids', o)
        .then((response) => {
          this.selected = response.data
          if (this.stessaDestinazione()) {
            this.$router.push({ name: 'ddtMail', query: { ddts: this.selected } });
            this.selected = []
          } else {
            $EventBus.$emit('message', { type: 'error', text: 'I DDT selezionati sono di Clienti diversi, impossibile inviarli insieme.' })
          }
        }).catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile ottenere gli id dei DDT' })
        })
    }, */
    cerca () {
      this.getDdts()
    },
    getAddr (d) {
      let addr
      if (d.destinazione_id) {
        addr = d.presso_destinazione
      } else {
        addr = 'Cliente non in anagrafica'
      }
      return addr
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
        this.changePage(1)
      }
    },
    toggleRicerca () {
      if (!this.showRicerca && this.clienti.length === 0) {
        this.getClienti()
      }
      this.showRicerca = !this.showRicerca
      this.search = null
      this.ricerca = {}
    },
    getClienti () {
      this.$plsqlMethod('ddtp', 'get_clienti', { })
        .then(response => {
          this.clienti = response.data
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Clienti' })
        })
    },
    cercaDest (val) {
      if (!val) {
        return
      }
      this.destLoading = true

      this.$plsqlMethod('destinazione', 'search', { text: val })
        .then(response => {
          this.destinazioni = response.data
          this.destLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Destinazioni' })
          this.destLoading = false
        })
    },
    cercaOpere (val) {
      if (!val) {
        return
      }
      this.opereLoading = true

      this.$plsqlMethod('opera', 'search', { text: val, attivo: 1 })
        .then(response => {
          this.opere = response.data
          this.opereLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Opere' })
          this.opereLoading = false
        })
    }
  },
  watch: {
    selected (val) {
      this.changeSel(val)
    },
    searchDest (val) {
      this.debouncedcercaDest(val)
    },
    searchOpere (val) {
      this.debouncedcercaOpere(val)
    }
  },
  created: function () {
    this.debouncedcercaDest = _debounce(this.cercaDest, 500)
    this.debouncedcercaOpere = _debounce(this.cercaOpere, 500)
  },
  mounted () {
    this.getDdts()
  }
}
</script>
